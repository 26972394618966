import { css } from "~/design/generated/css";
import { Logomark } from "./Logomark";

export function Logo() {
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
        gap: 2,
      })}
    >
      <Logomark className={css({ width: "40px" })} />
      <div
        className={css({
          display: { base: "none", md: "block" },
          fontWeight: "semibold",
          fontSize: "xl",
          mb: "-4px",
        })}
      >
        Dials
      </div>
    </div>
  );
}
