import { css } from "~/design/generated/css";

export function Logomark(props) {
  return (
    <svg viewBox="0 0 31 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3516 0.895752C16.227 -0.298584 14.487 -0.298584 14.3624 0.895752L13.8265 6.03638C13.7314 6.94849 12.5609 7.26196 12.0223 6.51978L8.98798 2.33618C8.28253 1.36353 6.7757 2.23364 7.26532 3.33032L9.37128 8.05005C9.74506 8.88794 8.88824 9.74438 8.0506 9.37134L3.33099 7.26489C2.2337 6.77563 1.36371 8.28247 2.33636 8.98755L6.52008 12.0217C7.26251 12.5608 6.94891 13.7307 6.03668 13.8264L0.896301 14.3625C-0.298767 14.4866 -0.298767 16.2268 0.896301 16.3508L0.952454 16.3567H29.7615L29.8177 16.3508C31.0128 16.2268 31.0128 14.4866 29.8177 14.3625L24.6773 13.8264C23.7651 13.7307 23.4515 12.5608 24.1939 12.0217L28.3776 8.98755C29.3503 8.28247 28.4803 6.77563 27.383 7.26489L22.6634 9.37134C21.8257 9.74438 20.9689 8.88794 21.3427 8.05005L23.4487 3.33032C23.9383 2.23364 22.4315 1.36353 21.726 2.33618L18.6917 6.51978C18.1531 7.26196 16.9826 6.94849 16.8875 6.03638L16.3516 0.895752Z"
        fill="currentColor"
        className={css({ color: "amber.light.8" })}
      />
    </svg>
  );
}
